<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"
            :class="{ 'is-flex-items-center' : showIcon}">
            <span v-if="showIcon"
              class="icon is-medium"
              :class="`has-text-${iconType}`"
              style="margin-right: 0.5rem">
              <i class="mdi mdi-36px"
                :class="`${iconName}`" />
            </span>
            <span class="ml-2">Authorise</span>
          </p>
        </header>
        <section class="modal-card-body">
          <article>
            <div>
              <p class="has-text-success has-text-weight-semibold is-size-6">Final value to be authorise: {{ totalSelectedItems | formatCurrency($userInfo.locale) }}</p>
            </div>
            <div class="mt-1 columns">
              <div class="column">
                <div class="field">
                  <label class="label">Claim Number</label>
                  <div class="control">
                    <input class="input"
                      v-model="quoteValues.claimNo"
                      type="text"
                      placeholder="Claim Number">
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Assessor</label>
                  <div class="control">
                    <input class="input is-capitalized"
                      v-model="quoteValues.assessorName"
                      type="text"
                      placeholder="Assessor">
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Excess Amount</label>
                  <div class="control">
                    <vue-numeric class="input"
                      v-model="quoteValues.excess"
                      :min="0"
                      format
                      placeholder="Excess"
                      :symbol="$company.currency.symbol"
                      :precision="2" />
                  </div>
                </div>
              </div>
              <div class="column" />
            </div>
          </article>
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button class="button is-success tooltip"
            @click="authorise()"
            data-tooltip="Authorise this quote"
            :disabled="disableAuthorise">Authorise</button>
          <button class="button tooltip is-tooltip-topright"
            @click="cancel()"
            data-tooltip="Cancel Authorisation">Cancel</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import VueNumeric from '@/components/VueNumeric'
import { LabourItemCodeTypes } from '@/enums'
import { mapGetters } from 'vuex'
import { FocusInserted } from '@/components/directives'
import { NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'AuthoriseModal',
  directives: {
    FocusInserted
  },
  components: {
    BaseModalEx,
    VueNumeric
  },
  mixins: [NumberFiltersMixin],
  props: {
    quoteValues: null,
    vehicleValues: null,
    selectedItems: null,
    totalSelectedItems: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-playlist-check'
    }
  },
  data: () => {
    return {
      isActive: false
    }
  },
  computed: {
    disableAuthorise() {
      return false
    },
    labourItemCodeTypes: function () {
      return LabourItemCodeTypes
    },
    ...mapGetters('vehicleOptions', {
      storePaintGroups: 'paintGroups'
    }),
    paintGroups: function () {
      return this.storePaintGroups
    },
    paintRate() {
      const paintRate = this.quoteValues.rates.find((r) => r.labourCodeId === this.vehicleValues.paintGroup)
      return paintRate !== undefined ? paintRate : { rate: null }
    },
    repairRate() {
      const repairRate = this.quoteValues.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.REP)
      return repairRate !== undefined ? repairRate : { rate: null }
    },
    mechanicalRate() {
      const mechanicalRate = this.quoteValues.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.MECH)
      return mechanicalRate !== undefined ? mechanicalRate : { rate: null }
    },
    rrRate() {
      const rrRate = this.quoteValues.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.RR)
      return rrRate !== undefined ? rrRate : { rate: null }
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.isActive = this.active || false
  },
  beforeDestroy() {},
  methods: {
    authorise() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('authorise')
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
      this.$emit('cancel')
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
.is-flex-items-end {
  display: flex;
  align-items: flex-end;
}
.modal-card-body {
  min-height: 15em;
}
.button.is-static {
  min-width: 4.5rem;
}
</style>
