<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"
            :class="{ 'is-flex-items-center' : showIcon}">
            <span v-if="showIcon"
              class="icon is-medium">
              <i class="mdi mdi-36px mdi-pencil" />
            </span>
            <span class="ml-2">Edit Rates</span>
          </p>
        </header>
        <section class="modal-card-body">
          <article>
            <div>
              <p class="has-text-info  is-size-5 mb-3">Labour Rates</p>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">RR Rate</label>
                  <div class="control">
                    <vue-numeric class="input"
                      v-model="rrRate.rate"
                      :min="0"
                      format
                      placeholder="Paint Rate"
                      :symbol="$company.currency.symbol"
                      :precision="2" />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Mechanical Rate</label>
                  <div class="control">
                    <vue-numeric class="input"
                      v-model="mechanicalRate.rate"
                      :min="0"
                      format
                      placeholder="Paint Rate"
                      :symbol="$company.currency.symbol"
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Repair Rate</label>
                  <div class="control">
                    <vue-numeric class="input"
                      v-model="repairRate.rate"
                      :min="0"
                      format
                      placeholder="Paint Rate"
                      :symbol="$company.currency.symbol"
                      :precision="2" />
                  </div>
                </div>
              </div>
              <div class="column" />
            </div>
            <div>
              <p class="has-text-info is-size-5 mb-3">Paint Rates</p>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Paint Group</label>
                  <div class="control is-expanded">
                    <div class="select is-fullwidth">
                      <select v-model="vehicleValues.paintGroup"
                        class=" control-min-width"
                        ref="paintGroup"
                        @change="selectPaintGroup($event)">
                        <option value="null"
                          disabled>Select</option>
                        <option v-for="(p, index) in paintGroups"
                          :key="index"
                          :value="p.key">
                          {{ p.value }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Paint Rate</label>
                  <div class="control">
                    <vue-numeric class="input"
                      v-model="paintRate.rate"
                      :min="0"
                      format
                      placeholder="Paint Rate"
                      :symbol="$company.currency.symbol"
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button class="button is-success tooltip"
            @click="applyRates()"
            data-tooltip="Save quote labour rates">
            <span>
              OK
            </span>
          </button>
          <button class="button tooltip is-tooltip-topright"
            @click="cancel()"
            data-tooltip="Cancel editing quote labour rates">Cancel</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import VueNumeric from '@/components/VueNumeric'
import { LabourItemCodeTypes } from '@/enums'
import { mapGetters } from 'vuex'
import { FocusInserted } from '@/components/directives'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'EditRatesModal',
  directives: {
    FocusInserted
  },
  components: {
    BaseModalEx,
    VueNumeric
  },
  mixins: [NumberFiltersMixin],
  props: {
    quoteValues: null,
    vehicleValues: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      isActive: false,
      rates: []
    }
  },
  computed: {
    labourItemCodeTypes() {
      return LabourItemCodeTypes
    },
    ...mapGetters('vehicleOptions', {
      storePaintGroups: 'paintGroups'
    }),
    paintGroups() {
      return this.storePaintGroups
    },
    paintRate() {
      const paintRate = this.rates.find((r) => r.labourCodeId === this.vehicleValues.paintGroup)
      return paintRate !== undefined ? paintRate : { rate: null }
    },
    repairRate() {
      const repairRate = this.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.REP)
      return repairRate !== undefined ? repairRate : { rate: null }
    },
    mechanicalRate() {
      const mechanicalRate = this.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.MECH)
      return mechanicalRate !== undefined ? mechanicalRate : { rate: null }
    },
    rrRate() {
      const rrRate = this.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.RR)
      return rrRate !== undefined ? rrRate : { rate: null }
    }
  },
  watch: {},
  created() {
    this.rates = _cloneDeep(this.quoteValues.rates)
  },
  mounted() {
    this.isActive = this.active || false
  },
  beforeDestroy() {},
  methods: {
    applyRates() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('apply-rates', this.rates)
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
.is-flex-items-end {
  display: flex;
  align-items: flex-end;
}
.modal-card-body {
  min-height: 15em;
}
.button.is-static {
  min-width: 4.5rem;
}
</style>
